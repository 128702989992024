/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:7815912a-1afe-471a-85b6-d46b94913e41",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_wyWU8unsB",
    "aws_user_pools_web_client_id": "12b00ngjs3jfuj4n9e7dca5i4n",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "GIVEN_NAME",
        "FAMILY_NAME",
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "meadocumentsbucket182239-preprod",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
